/* img {
  max-width: 80%;
  height: auto;
  padding: 5rem;
} */

.footer {
  background-color: #f1f1f1; /* Light gray background */
  padding: 20px;
  text-align: center;
  left: 0;
  bottom: 0;
  width: 100%;
}

p {
  font-size: x-large;
}

